<template>
  <div>
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade"
      id="mdReset"
      tabindex="-1"
      aria-labelledby="mdResetLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-s p-5">
          <div class="text-center cp mb-4">
            <h5>{{ $t("labels.reset_password") }}</h5>
          </div>

          <form autocomplete="off" class="">
            <Alert :alerts="alert"></Alert>

            <div class="form-floating">
              <input
                v-model="password"
                autocomplete="off"
                :class="{ 'is-invalid': !passwordValide }"
                :type="passwordFieldType"
                class="form-control"
                placeholder=" "
              />
              <label for="floatingPassword">{{
                $t("labels.new_password")
              }}</label>
              <div class="invalid-feedback">
                {{ $t("labels.minimum_password_length") }}
              </div>
              <div class="form-text text-end">
                <img
                  @click="showHidePassword"
                  v-if="passwordFieldType == 'password'"
                  class="ico-show-hide"
                  src="../assets/img/eye_show.svg"
                  alt=""
                />
                <img
                  @click="showHidePassword"
                  v-else
                  class="ico-show-hide"
                  src="../assets/img/eye_hide.svg"
                  alt=""
                />
              </div>
            </div>

            <div class="form-floating mb-3">
              <input
                v-model="confirmePassword"
                autocomplete="off"
                :class="{ 'is-invalid': !confirmePasswordValide }"
                :type="passwordFieldType"
                class="form-control"
                placeholder="Password"
              />
              <label>{{ $t("labels.confirm_password") }}</label>
            </div>

            <div class="d-grid gap-2 mt-3">
              <button
                :disabled="loading"
                v-on:click="reset"
                class="btn btn-primary"
                type="button"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ $t("buttons.save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api.js";
import Alert from "@/components/alert/Alert";
import { Modal } from "bootstrap";

export default {
  name: "Footer",
  components: {
    Alert,
  },
  mounted() {
    if (this.$route.params.token.length < 10)
      this.$router.push({ name: "Home" });
    else {
      this.modalReset = new Modal(document.getElementById("mdReset"), {
        backdrop: "static",
      });

      this.modalReset.show();
    }
  },

  data: function() {
    return {
      loading: false,
      password: "",
      confirmePassword: "",

      passwordValide: true,
      confirmePasswordValide: true,
      passwordFieldType: "password",

      modalReset: "",

      alert: {
        msg: "",
        type: "",
      },
    };
  },
  methods: {
    showHidePassword() {
      this.passwordFieldType =
        this.passwordFieldType == "password" ? "text" : "password";
    },
    reset() {
      this.passwordValide = this.$valid(this.password, 8);
      if (!this.passwordValide) return;

      this.confirmePasswordValide =
        this.password === this.confirmePassword ? true : false;
      if (!this.confirmePasswordValide) return;

      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("password", this.password);
      bodyFormData.append("password_confirmation", this.confirmePassword);

      bodyFormData.append("token", this.$route.params.token);
      bodyFormData.append("email", this.$route.params.email);

      api({
        method: "post",
        url: "/reset-password",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data; " },
      })
        .then((response) => {
          if (!response.data.error) {
            this.alert.msg = response.data.messages;
            this.alert.type = "success";

            localStorage.setItem("token", response.data.data.token);
            api.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.data.token;

            this.$store.dispatch("user", response.data.data.user);

            setTimeout(() => {
              this.modalReset.hide();
              this.$router.push({ name: "Curriculum" });
            }, 3000);
          } else {
            if (response.data.messages) throw new Error(response.data.messages);

            throw new Error("Internal Server Error");
          }
          this.loading = false;
        })
        .catch((error) => {
          this.alert.msg = error.message;
          this.alert.type = "danger";
          this.loading = false;
        });
    },
  },
};
</script>
